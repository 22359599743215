
export default class Subpopulation {
  constructor(variable, categories) {
    this.variable = variable;
    this.categoryMap = new Map();
    this.selectedCategoryMap = new Map();

    // Vue can't track changes done to Maps.  The version field is used to 'dirty' the object in the setter methods
    this.version = 1;

    for (let c of categories) {
      this.categoryMap.set(c.id, c);
    }
  }

  get categories() {
    return this.version && [...this.categoryMap.values()];
  }

  get count() {
    return this.version && this.categoryMap.size;
  }

  get selectedCategories() {
    return this.version && [...this.selectedCategoryMap.values()];
  }

  get selectedCount() {
    return this.version && this.selectedCategoryMap.size;
  }

  addCategory(category) {
    this.version = this.version + 1;
    const id = this.getCategoryId(category);
    const cat = this.categoryMap.get(id);
    if (cat) {
      this.selectedCategoryMap.set(id, cat);
    }
  }

  removeCategory(category) {
    this.version = this.version + 1;
    const id = this.getCategoryId(category);
    this.selectedCategoryMap.delete(id);
  }

  clear() {
    this.version = this.version + 1;
    this.selectedCategoryMap.clear();
  }

  isCategorySelected(category) {
    const id = this.getCategoryId(category);
    return this.selectedCategoryMap.has(id);
  }

  dup() {
    const copy = new Subpopulation(this.variable, this.categories);
    for (let c of this.selectedCategories) {
      copy.addCategory(c);
    }

    return copy;
  }

  getCategoryId(category) {
    if (typeof category === "string" || typeof category === "number") {
      return category.toString();
    }

    return category.id;
  }
}